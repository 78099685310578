/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'ITC Book';
    font-style: normal;
    font-weight: normal;
    src: url('itc-avant-garde-gothic-std-book-58957161d80eb.otf') format('opentype');
}

@font-face {
    font-family: 'ITC Light';
    font-style: normal;
    font-weight: normal;
    src: url('itc-avant-garde-gothic-std-extra-light-5895708744eb6.otf') format('opentype');
}

@font-face {
    font-family: 'ITC Bold';
    font-style: normal;
    font-weight: normal;
    src: url('itc-avant-garde-gothic-std-bold-condensed-5895705e8fe31.otf') format('opentype');
}

@font-face {
    font-family: 'ITC Medium';
    font-style: normal;
    font-weight: normal;
    src: url('itc-avant-garde-gothic-std-medium-condensed-5895720edc668.otf') format('opentype');
}

@font-face {
    font-family: 'ITC Demi';
    font-style: normal;
    font-weight: normal;
    src: url('itc-avant-garde-gothic-std-demi-589572a199962.otf') format('opentype');
}
