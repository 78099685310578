.div-display-package {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.number-package {
    background-color: #005347 !important;
    padding: 5px !important;
    color: white !important;
}

.fragile-container-tag {
    background-color: #FFAE4F !important;
    height: 22px;
    align-self: center;
}

.fragile-text-tag {
    color: #1D1D1B;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.01em;
    line-height: 16px;
}

.text-package {
    height: fit-content;
    align-self: center;
}

.align-self-center {
    align-self: center;
}